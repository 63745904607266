import {AppBar, Box, IconButton, Toolbar, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {AccountCircle} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {workBridge} from 'environment/constants';
import {routes} from 'environment/constants/routes';
import {Breadcrumbs} from 'molecules';

interface HeaderProps {
  drawerWidth: number;
  headerHeight: number;
  handleDrawerToggle: () => void;
}

const Header = ({
  drawerWidth,
  headerHeight,
  handleDrawerToggle,
}: HeaderProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const logOut = () => {
    navigate(routes.login);
    sessionStorage.removeItem(workBridge);
    localStorage.removeItem(workBridge);
  };

  return (
    <AppBar
      color="default"
      position="fixed"
      sx={{
        width: {sm: `calc(100% - ${drawerWidth}px)`},
        ml: {sm: `${drawerWidth}px`},
      }}>
      <Toolbar
        sx={{
          height: headerHeight + 'px',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{mr: 2, display: {sm: 'none'}}}>
            <MenuIcon />
          </IconButton>
          <Breadcrumbs />
        </Box>
        <Box
          onClick={logOut}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}>
          <AccountCircle />
          <Typography variant="h6" component="span">
            {t('Log.out')}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
