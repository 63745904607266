import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import i18n from 'i18next';

const password = Yup.string()
  .min(8, `${i18n.t('Minimum.number.of.ranks')} 8`)
  .max(32, `${i18n.t('Maximum.number.of.ranks')} 32`)
  .required(i18n.t('Enter.passswrod'))
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!$%^&*()_+|~=`{}[\]:";'<>?,./@ \\#])(?=.{8,})/,
    i18n.t(
      'Must.contain.One.Uppercase.and.One.Number.and.One.Special.Character',
    ),
  )
  .matches(/^(\S+$)/, i18n.t('This.field.must.not.contain.spaces'));

  const email = Yup.string()
  .email(`${i18n.t('Must.be.a.valid.email')}`)
  .required(i18n.t('Enter.email'))
  .matches(
    /^[\w\-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
    i18n.t('Must.be.a.valid.email'),
  );

export const LOGIN_SCHEMA = yupResolver(
  Yup.object().shape({
    email: email,
    password: password,
  }),
);

export const EDIT_SEEKER_SCHEMA = yupResolver(
  Yup.object().shape({
    id: Yup.string(),
    email: Yup.string(),
    firstName: Yup.string()
      .min(2, `${i18n.t('Minimum.number.of.symbols')} 2`)
      .max(30, `${i18n.t('Maximum.number.of.symbols')} 30`)
      .required(i18n.t('Enter.first.name'))
      .trim(),
    lastName: Yup.string()
      .min(2, `${i18n.t('Minimum.number.of.symbols')} 2`)
      .max(30, `${i18n.t('Maximum.number.of.symbols')} 30`)
      .required(i18n.t('Enter.last.name'))
      .trim(),
    dob: Yup.string().required(i18n.t('Select.date.of.birth')),
    gender: Yup.string().required(i18n.t('Select.your.gender')),
    securityNumber: Yup.string()
      .required(i18n.t('Select.your.security.number'))
      .matches(/^(\d+$)/, i18n.t('This.field.must.contain.only.numbers'))
      .trim(),
  }),
);

export const EDIT_EMPLOYER_SCHEMA = yupResolver(
  Yup.object().shape({
    id: Yup.string(),
    email: email,
    firstName: Yup.string()
      .min(2, `${i18n.t('Minimum.number.of.symbols')} 2`)
      .max(30, `${i18n.t('Maximum.number.of.symbols')} 30`)
      .required(i18n.t('Enter.first.name'))
      .trim(),
    lastName: Yup.string()
      .min(2, `${i18n.t('Minimum.number.of.symbols')} 2`)
      .max(30, `${i18n.t('Maximum.number.of.symbols')} 30`)
      .required(i18n.t('Enter.last.name'))
      .trim(),
    companyName: Yup.string().required(i18n.t('Enter.restaurant.chain')).trim(),
  }),
);

export const ADD_HISTORY_SCHEMA = yupResolver(
  Yup.object().shape({
    message: Yup.string()
      .min(4, `${i18n.t('Minimum.number.of.symbols')} 4`)
      .max(100, `${i18n.t('Maximum.number.of.symbols')} 100`)
      .required(i18n.t('Enter.company'))
      .trim(),
  }),
);
