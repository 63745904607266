import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

const NotFound = () => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}>
      <Typography sx={{color: '#797979'}} variant={'h1'}>
        {t('Not.Found.Page')}
      </Typography>
    </Box>
  );
};

export default NotFound;
