import {createModel} from '@rematch/core';
import {AxiosError} from 'axios';
import {toast} from 'react-toastify';
import {t} from 'i18next';

import type {RootModel} from '.';
import {
  editEmployerRequest,
  getEmployerRequest,
  getEmployersRequest,
  verifyEmployerRequest,
} from 'environment/api/services/employers';
import {EmployersData, EmployersState} from 'environment/types/Employer';

const employer = {
  id: '',
  email: '',
  name: '',
  companyName: '',
  verified: true,
  createdAt: '',
  updatedAt: '',
  password: '',
};

export const employers = createModel<RootModel>()({
  state: {
    employers: [],
    employer: employer,
  } as EmployersState,
  reducers: {
    setEmployers(state, payload: EmployersData[]) {
      return {
        ...state,
        employers: payload,
      };
    },
    setEmployer(state, payload: EmployersData) {
      return {
        ...state,
        employer: payload,
      };
    },
  },
  effects: {
    async getEmployers(payload) {
      try {
        const {status, data} = await getEmployersRequest(payload.query);
        if (status === 200) {
          this.setEmployers(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async getEmployer(payload) {
      try {
        const {status, data} = await getEmployerRequest(payload.id);
        if (status === 200) {
          this.setEmployer(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async editEmployer(payload) {
      const {id, body} = payload;
      try {
        const {status, data} = await editEmployerRequest(id, body);
        if (status === 200) {
          this.setEmployer(data);
          toast.success(t('Changes.were.successfully.saved'));
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async verifyEmployer(payload) {
      const {id, verified} = payload;
      try {
        const {status, data} = await verifyEmployerRequest(id, verified);
        if (status === 200) {
          this.setEmployer(data);
          toast.success(t('Changes.were.successfully.saved'));
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
  },
});
