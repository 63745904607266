import {Route, Routes} from 'react-router-dom';

import {
  Dashboard,
  EditEmployer,
  EditSeeker,
  Employers,
  History,
  JobDetails,
  JobList,
  JobRequestsDetails,
  JobRequestsList,
  LogIn,
  NotFound,
  Seekers,
} from 'page';
import {routes} from 'environment/constants/routes';

const AppRouter = () => {
  return (
    <Routes>
      <Route path={routes.login} element={<LogIn />}></Route>
      <Route path={routes.dashboard} element={<Dashboard />}>
        <Route path={routes.seeker.seekers} element={<Seekers />} />
        <Route path={routes.seeker.seekersId} element={<EditSeeker />} />
        <Route path={routes.employers.employers} element={<Employers />} />
        <Route path={routes.employers.employersId} element={<EditEmployer />} />
        <Route path={routes.jobs.list} element={<JobList />} />
        <Route path={routes.jobs.listDetailsId} element={<JobDetails />} />
        <Route path={routes.jobs.requestsList} element={<JobRequestsList />} />
        <Route
          path={routes.jobs.requestsDetailsId}
          element={<JobRequestsDetails />}
        />
        <Route path={routes.history} element={<History />} />
      </Route>
      <Route path={routes.notFound} element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;
