import {createModel} from '@rematch/core';
import {AxiosError} from 'axios';
import {toast} from 'react-toastify';
import {t} from 'i18next';

import type {RootModel} from '.';
import {
  JobRequestDetails,
  JobRequestsHistory,
  JobRequestsList,
  JobsListDetails,
  JobsState,
} from 'environment/types/Jobs';
import {
  addHistoryRequest,
  changeJobRequstStatusRequest,
  changeJobStatusRequest,
  deleteAllHistoryRequest,
  getJobDetailsRequest,
  getJobRequestDetailsRequest,
  getJobRequestsListRequest,
  getJobsRequest,
  getRequestHistoryRequest,
} from 'environment/api/services/jobs';

export const jobs = createModel<RootModel>()({
  state: {
    jobsList: [],
    jobDetails: null,
    jobRequestsList: [],
    jobRequestDetails: null,
    jobRequestsHistory: [],
  } as JobsState,
  reducers: {
    setJobsList(state, payload: JobsListDetails[]) {
      return {
        ...state,
        jobsList: payload,
      };
    },

    setJobDetails(state, payload: JobsListDetails) {
      return {
        ...state,
        jobDetails: payload,
      };
    },
    setJobRequestsList(state, payload: JobRequestsList[]) {
      return {
        ...state,
        jobRequestsList: payload,
      };
    },
    setJobRequestsDetails(state, payload: JobRequestDetails) {
      return {
        ...state,
        jobRequestDetails: payload,
      };
    },
    setJobRequestsHistory(state, payload: JobRequestsHistory[]) {
      return {
        ...state,
        jobRequestsHistory: payload,
      };
    },
    updateJobRequestsHistory(state, payload: JobRequestsHistory) {
      return {
        ...state,
        jobRequestsHistory: [...state.jobRequestsHistory, payload],
      };
    },
    resetJobDetails(state) {
      return {
        ...state,
        jobDetails: null,
      };
    },
    resetJobRequestsDetails(state) {
      return {
        ...state,
        jobRequestDetails: null,
      };
    },
  },
  effects: {
    async getJobs(payload) {
      try {
        const {status, data} = await getJobsRequest(payload.query);
        if (status === 200) {
          this.setJobsList(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async getJobDetails(payload) {
      this.resetJobDetails();
      try {
        const {status, data} = await getJobDetailsRequest(payload.id);
        if (status === 200) {
          this.setJobDetails(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async changeJobStatus(payload) {
      const {id, query} = payload;
      try {
        const {status, data} = await changeJobStatusRequest(id, query);
        if (status === 200) {
          this.setJobDetails(data);
          toast.success(t('Changes.were.successfully.saved'));
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async getJobRequsts(payload) {
      try {
        const {status, data} = await getJobRequestsListRequest(payload.query);
        if (status === 200) {
          this.setJobRequestsList(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async getJobRequestDetails(payload) {
      this.resetJobRequestsDetails();
      const {id, seekerId} = payload;
      try {
        const {status, data} = await getJobRequestDetailsRequest(id, seekerId);
        if (status === 200) {
          this.setJobRequestsDetails(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async changeJobRequstStatus(payload) {
      const {id, seekerId, query} = payload;
      try {
        const {status, data} = await changeJobRequstStatusRequest(
          id,
          seekerId,
          query,
        );
        if (status === 200) {
          this.setJobRequestsDetails(data);
          this.getRequestHistory({id, seekerId});
          toast.success(t('Changes.were.successfully.saved'));
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async getRequestHistory(payload) {
      const {id, seekerId} = payload;
      try {
        const {status, data} = await getRequestHistoryRequest(id, seekerId);
        if (status === 200) {
          this.setJobRequestsHistory(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async deleteAllHistory(payload) {
      const {id, seekerId} = payload;
      try {
        const {status} = await deleteAllHistoryRequest(id, seekerId);
        if (status === 200) {
          this.setJobRequestsHistory([]);
          toast.success(t('Changes.were.successfully.saved'));
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async addHistory(payload) {
      const {id, seekerId, body} = payload;
      try {
        const {status, data} = await addHistoryRequest(id, seekerId, body);
        if (status === 201) {
          this.updateJobRequestsHistory(data);
          toast.success(t('Changes.were.successfully.saved'));
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
  },
});
