import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {Dispatch, RootState} from 'environment/store';
import {JobDetailsTemplate} from 'templates';
import {Status} from 'environment/types/Jobs';

const JobDetails = () => {
  const dispatch = useDispatch<Dispatch>();
  const params = useParams();

  const {jobDetails} = useSelector((state: RootState) => state.jobs);

  const [jobStatus, setJobStatus] = useState<Status>(
    jobDetails?.status || 'inactive',
  );

  useEffect(() => {
    const jobId = params?.id?.replace(/\D/g, '');
    dispatch.jobs.getJobDetails({id: jobId});
  }, [dispatch, params.id]);

  useEffect(() => {
    if (jobDetails?.status) {
      setJobStatus(jobDetails.status);
    }
  }, [jobDetails?.status]);

  const onChangeStatus = () => {
    const switchStatusTo = jobStatus === 'active' ? 'inactive' : 'active';
    setJobStatus(switchStatusTo);

    const query = 'status=' + switchStatusTo;
    dispatch.jobs.changeJobStatus({id: jobDetails?.id, query});
  };

  return (
    <JobDetailsTemplate
      jobDetails={jobDetails}
      jobStatus={jobStatus === 'active'}
      onChangeStatus={onChangeStatus}
    />
  );
};

export default JobDetails;
