import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {Dispatch, RootState} from 'environment/store';
import {EditSeekerTemplate} from 'templates';
import {EDIT_SEEKER_SCHEMA} from 'environment/validation';
import {EditValues} from 'environment/types/Seeker';
import {routes} from 'environment/constants/routes';

const EditSeeker = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const params = useParams();

  const {seeker} = useSelector((state: RootState) => state.seekers);
  const loading = useSelector(
    (rootState: RootState) => rootState.loading.models.seekers,
  );

  useEffect(() => {
    const seekerId = params?.id?.replace(/\D/g, '');
    dispatch.seekers.getSeeker({id: seekerId});
  }, [dispatch, params.id]);

  const setDefaultValues = (seeker: EditValues) => {
    return {
      id: seeker.id,
      email: seeker.email || '',
      firstName: seeker.firstName,
      lastName: seeker.lastName,
      securityNumber: seeker.securityNumber,
      dob: seeker.dob,
      gender: seeker.gender,
    };
  };

  const {
    reset,
    handleSubmit,
    control,
    formState: {isDirty},
  } = useForm<EditValues>({
    mode: 'onChange',
    defaultValues: setDefaultValues(seeker),
    resolver: EDIT_SEEKER_SCHEMA,
  });

  useEffect(() => {
    reset(setDefaultValues(seeker));
  }, [reset, seeker]);

  const onSubmit = (values: EditValues) => {
    if (!isDirty) {
      return;
    }

    const {id, firstName, lastName, dob, gender, securityNumber} = values;

    const data = {
      firstName,
      lastName,
      dob: new Date(dob).toJSON(),
      gender,
      securityNumber,
    };

    dispatch.seekers.editSeeker({id, body: data});
  };

  const navigateToSeekers = () => {
    navigate(routes.seeker.seekers);
  };

  return (
    <EditSeekerTemplate
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={navigateToSeekers}
      loading={loading}
    />
  );
};

export default EditSeeker;
