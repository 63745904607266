import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {Dispatch, RootState} from 'environment/store';
import {EmployersTemplate} from 'templates';
import {CellData, EmployersData} from 'environment/types/Employer';
import {Order} from 'environment/types';
import {routes} from 'environment/constants/routes';
import {formatDate} from 'environment/utils/formatDate';

const Employers = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const {employers} = useSelector((state: RootState) => state.employers);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof CellData>('id');

  useEffect(() => {
    dispatch.employers.getEmployers({query: ''});
  }, [dispatch.employers]);

  const handleRequestSort = (property: keyof CellData) => {
    const isAsc = orderBy === property && order === 'asc';
    const query = `${property}=${isAsc ? 'desc' : 'asc'}`;

    dispatch.employers.getEmployers({query});
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const navigateToEditPage = (values: EmployersData) => {
    navigate(`${routes.employers.employers}/employer-${values.id}`);
  };

  const bodyRows = employers.map(value => ({
    id: value.id,
    name: value.name,
    companyName: value.companyName,
    createdAt: formatDate(value.createdAt),
    updatedAt: formatDate(value.updatedAt),
  }));

  return (
    <EmployersTemplate
      orderBy={orderBy}
      order={order}
      bodyRows={bodyRows}
      handleRequestSort={handleRequestSort}
      navigateToEditPage={navigateToEditPage}
    />
  );
};

export default Employers;
