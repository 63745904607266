import {useTranslation} from 'react-i18next';

import {TableBody, TableHead} from 'molecules';
import {Order} from 'environment/types';
import {TableContainer} from 'atoms';
import {CellData, EmployersData, HeadCell} from 'environment/types/Employer';

interface EmployersProps {
  orderBy: keyof CellData;
  order: Order;
  bodyRows: Partial<EmployersData>[];
  handleRequestSort: (property: keyof CellData) => void;
  navigateToEditPage: (values: EmployersData) => void;
}

const EmployersTemplate = ({
  orderBy,
  order,
  bodyRows,
  handleRequestSort,
  navigateToEditPage,
}: EmployersProps) => {
  const {t} = useTranslation();

  const headCells: HeadCell[] = [
    {
      value: 'id',
      label: t('Id'),
      sort: false,
    },
    {
      value: 'name',
      label: t('Name'),
      sort: true,
    },
    {
      value: 'companyName',
      label: t('Company.name'),
      sort: true,
    },
    {
      value: 'createdAt',
      label: t('Created.at'),
      sort: true,
    },
    {
      value: 'updatedAt',
      label: t('Updated.at'),
      sort: true,
    },
    {
      value: 'edit',
      label: t('Edit'),
      sort: false,
    },
  ];

  return (
    <TableContainer>
      <TableHead<CellData>
        headCells={headCells}
        orderBy={orderBy}
        order={order}
        sortHandler={handleRequestSort}
      />
      <TableBody rows={bodyRows} editBtn editHandler={navigateToEditPage} />
    </TableContainer>
  );
};

export default EmployersTemplate;
