import {Models} from '@rematch/core';
import {auth} from './auth';
import {seekers} from './seekers';
import {employers} from './employers';
import {jobs} from './jobs';
import {history} from './history';

export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  seekers: typeof seekers;
  employers: typeof employers;
  jobs: typeof jobs;
  history: typeof history;
}

export const models: RootModel = {
  auth,
  seekers,
  employers,
  jobs,
  history,
};
