import {Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {Button, TableContainer} from 'atoms';
import {JobRequestsHistory} from 'environment/types/Jobs';
import {formatDate} from 'environment/utils/formatDate';
import {TableBody, TableHead} from 'molecules';

interface JobRequestHistoryProps {
  jobRequestsHistory: JobRequestsHistory[];
  onOpenModal: () => void;
  onDeleteAllHistory: () => void;
}

const JobRequestHistoryTable = ({
  jobRequestsHistory,
  onDeleteAllHistory,
  onOpenModal,
}: JobRequestHistoryProps) => {
  const {t} = useTranslation();

  const headCellsRequestHistory = [
    {
      value: 'id',
      label: t('Id'),
      sort: false,
    },
    {
      value: 'job_request_id',
      label: t('Job.request.id'),
      sort: false,
    },
    {
      value: 'message',
      label: t('Message'),
      sort: false,
    },
    {
      value: 'createdAt',
      label: t('Created.at'),
      sort: false,
    },
    {
      value: 'updatedAt',
      label: t('Updated.at'),
      sort: false,
    },
  ];

  const showJobRequestsHistory = jobRequestsHistory.map(value => {
    return {
      id: value.id,
      jobRequestId: value.job_request_id,
      message: value.message,
      createdAt: formatDate(value.createdAt),
      updatedAt: formatDate(value.updatedAt),
    };
  });

  return (
    <>
      <Stack mt={6} direction="row" justifyContent="space-between">
        <Button onClick={onOpenModal} color="success">
          {t('Add.history')}
        </Button>
        {jobRequestsHistory.length > 0 && (
          <Button onClick={onDeleteAllHistory} color="error">
            {t('Delete.all.history')}
          </Button>
        )}
      </Stack>
      {jobRequestsHistory.length > 0 && (
        <>
          <Typography variant="h5" align="center">
            {t('History')}
          </Typography>
          <TableContainer>
            <TableHead<typeof headCellsRequestHistory>
              headCells={headCellsRequestHistory}
            />
            <TableBody rows={showJobRequestsHistory} />
          </TableContainer>
        </>
      )}
    </>
  );
};

export default JobRequestHistoryTable;
