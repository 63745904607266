import {createModel} from '@rematch/core';
import {AxiosError} from 'axios';
import {toast} from 'react-toastify';

import type {RootModel} from '.';
import {HistoryState} from 'environment/types/History';
import {getHistoryRequest} from 'environment/api/services/history';
import {HistoryData} from 'environment/types/History';

export const history = createModel<RootModel>()({
  state: {
    history: [],
  } as HistoryState,
  reducers: {
    setHistory(state, payload: HistoryData[]) {
      return {
        ...state,
        history: payload,
      };
    },
  },
  effects: {
    async getHistory() {
      try {
        const {status, data} = await getHistoryRequest();
        if (status === 200) {
          this.setHistory(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
  },
});
