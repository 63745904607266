import {
  TextField,
  InputAdornment,
  IconButton,
  TextFieldProps,
} from '@mui/material';
import {VisibilityOff, Visibility} from '@mui/icons-material';
import {Controller, Control} from 'react-hook-form';
import {useState} from 'react';

interface InputProps
  extends Omit<TextFieldProps, 'error' | 'helperText' | 'type'> {
  showPassword?: boolean;
  control: Control<any>;
  name: string;
}

const Input = ({showPassword = false, name, control, ...props}: InputProps) => {
  const [showDots, setShowDots] = useState<boolean>(true);
  const {sx = {}, ...rest} = props;

  const inputAdornment = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={() => setShowDots(!showDots)}>
          {showDots ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => {
        return (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={error && error.message}
            type={showPassword && showDots ? 'password' : 'text'}
            InputProps={showPassword ? inputAdornment : undefined}
            sx={{'& .MuiInputBase-input': {p: '14px'}, ...sx}}
            {...rest}
          />
        );
      }}
    />
  );
};

export default Input;
