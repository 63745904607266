import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {Dispatch, RootState} from 'environment/store';
import {SeekersTemplate} from 'templates';
import {Order} from 'environment/types';
import {CellData, SeekerData} from 'environment/types/Seeker';
import {routes} from 'environment/constants/routes';
import {formatDate} from 'environment/utils/formatDate';

const Seekers = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const {seekers} = useSelector((state: RootState) => state.seekers);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof CellData>('id');

  useEffect(() => {
    dispatch.seekers.getSeekers({query: ''});
  }, [dispatch.seekers]);

  const handleRequestSort = (property: keyof CellData) => {
    const isAsc = orderBy === property && order === 'asc';
    const query = `${property}=${isAsc ? 'desc' : 'asc'}`;

    dispatch.seekers.getSeekers({query});
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const navigateToEditPage = (values: SeekerData) => {
    navigate(`${routes.seeker.seekers}/seeker-${values.id}`);
  };

  const bodyRows = seekers.map(value => ({
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    phoneNumber: value.phoneNumber,
    email: value.email,
    createdAt: formatDate(value.createdAt),
    updatedAt: formatDate(value.updatedAt),
  }));

  return (
    <SeekersTemplate
      orderBy={orderBy}
      order={order}
      bodyRows={bodyRows}
      handleRequestSort={handleRequestSort}
      navigateToEditPage={navigateToEditPage}
    />
  );
};

export default Seekers;
