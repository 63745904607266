import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Control} from 'react-hook-form';

import {Button, Input} from 'atoms';
import {EditValues} from 'environment/types/Seeker';

interface EditEmployerFormProps {
  control: Control<EditValues>;
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const EditEmployerForm = ({
  control,
  loading,
  onCancel,
  onSubmit,
}: EditEmployerFormProps) => {
  const {t} = useTranslation();

  const inputList = [
    {
      name: 'id',
      label: t('Employer.id'),
      disabled: true,
    },
    {
      name: 'email',
      label: t('Email'),
      disabled: false,
    },
    {
      name: 'firstName',
      label: t('First.name'),
      disabled: false,
    },
    {
      name: 'lastName',
      label: t('Last.name'),
      disabled: false,
    },
    {
      name: 'companyName',
      label: t('Restaurant.chain'),
      disabled: false,
    },
  ];

  return (
    <Box
      sx={{display: 'flex', flexDirection: 'column', width: '400px'}}
      component="form"
      onSubmit={onSubmit}
      autoComplete="off">
      {inputList.map(value => (
        <Box key={value.name} sx={{mb: 2, height: '75px'}}>
          <Input
            name={value.name}
            disabled={value.disabled}
            control={control}
            label={value.label}
            fullWidth
          />
        </Box>
      ))}
      <Box sx={{display: 'flex', gap: '50px'}}>
        <Button onClick={onCancel} fullWidth color="error">
          {t('Cancel')}
        </Button>
        <Button fullWidth loading={loading} disabled={loading} type="submit">
          {t('Save')}
        </Button>
      </Box>
    </Box>
  );
};

export default EditEmployerForm;
