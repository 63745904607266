import {Box, Typography} from '@mui/material';
import {Control, FieldErrors} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {toUpperCaseFirstChar} from 'environment/utils/toUpperCaseFirstChar';
import {Button, Checkbox, Input} from 'atoms';
import {FormFieldsNames, LogInValues} from 'environment/types/LogIn';

const boxShadow = '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)';
const logInForm: FormFieldsNames[] = ['email', 'password'];

interface LogInTemplateProps {
  control: Control<LogInValues>;
  errors: FieldErrors<LogInValues>;
  loading: boolean;
  isRememberMe: boolean;
  onSubmit: () => void;
  handleRememberMe: () => void;
}

const LogInTemplate = ({
  control,
  errors,
  loading,
  isRememberMe,
  handleRememberMe,
  onSubmit,
}: LogInTemplateProps) => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          width: '500px',
          padding: '50px 20px',
          boxShadow,
          borderRadius: '10px',
        }}>
        <Box component="form" onSubmit={onSubmit} autoComplete="off">
          <Typography
            variant="h4"
            sx={{
              textAlign: 'center',
              mb: '20px',
            }}>
            {t('Log.in')}
          </Typography>
          {logInForm.map(fieldName => (
            <Box sx={{mb: 2, height: '75px'}} key={fieldName}>
              <Input
                name={fieldName}
                control={control}
                label={toUpperCaseFirstChar(fieldName)}
                showPassword={fieldName === 'password' ? true : false}
                fullWidth
              />
            </Box>
          ))}
          <Checkbox
            label={t('Remember.me')}
            checked={isRememberMe}
            onChange={handleRememberMe}
            sx={{mb: 2}}
          />
          <Button fullWidth loading={loading} disabled={loading} type="submit">
            {t('Log.in')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LogInTemplate;
