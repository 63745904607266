import {useState} from 'react';
import {Navigate} from 'react-router-dom';

import {workBridge} from 'environment/constants';
import {DashboardTemplate} from 'templates';
import {routes} from 'environment/constants/routes';

const Dashboard = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const accessTokenLS = localStorage.getItem(workBridge);
  const accessTokenSS = sessionStorage.getItem(workBridge);

  const handleDrawerToggle = () => {
    setMobileOpen(prev => !prev);
  };

  return !accessTokenLS && !accessTokenSS ? (
    <Navigate to={routes.login} replace={true} />
  ) : (
    <DashboardTemplate
      handleDrawerToggle={handleDrawerToggle}
      mobileOpen={mobileOpen}
    />
  );
};

export default Dashboard;
