import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';

import {LOGIN_SCHEMA} from 'environment/validation';
import {Dispatch, RootState} from 'environment/store';
import {LogInTemplate} from 'templates';
import {workBridge} from 'environment/constants';
import {routes} from 'environment/constants/routes';
import {LogInValues} from 'environment/types/LogIn';

const LogIn = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const accessTokenLS = localStorage.getItem(workBridge);
  const accessTokenSS = sessionStorage.getItem(workBridge);

  const [isRememberMe, setIsRememberMe] = useState<boolean>(false);
  const loading = useSelector(
    (rootState: RootState) => rootState.loading.models.auth,
  );

  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm<LogInValues>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: LOGIN_SCHEMA,
  });

  const onSubmit = (value: LogInValues) => {
    dispatch.auth.logIn({
      data: value,
      callback: (token: string) => {
        isRememberMe
          ? localStorage.setItem(workBridge, token)
          : sessionStorage.setItem(workBridge, token);
        navigate(routes.seeker.seekers);
      },
    });
  };

  const handleRememberMe = () => {
    setIsRememberMe(prev => !prev);
  };

  return accessTokenLS || accessTokenSS ? (
    <Navigate to={routes.seeker.seekers} replace={true} />
  ) : (
    <LogInTemplate
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      handleRememberMe={handleRememberMe}
      errors={errors}
      loading={loading}
      isRememberMe={isRememberMe}
    />
  );
};

export default LogIn;
