import {Typography} from '@mui/material';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

type SwitchOptions = {
  [key in 'left' | 'right']?: string;
};

type Color =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

interface SwitcherProps {
  active: boolean;
  onChange: () => void;
  switchOptions: SwitchOptions;
  color?: Color;
}

const Switcher = ({
  active,
  color = 'primary',
  switchOptions,
  onChange,
}: SwitcherProps) => {
  const renderText = (options: string | undefined) =>
    options && <Typography>{options}</Typography>;

  return (
    <Stack alignItems="center" direction="row">
      {renderText(switchOptions.left)}
      <Switch color={color} checked={active} onChange={onChange} />
      {renderText(switchOptions.right)}
    </Stack>
  );
};

export default Switcher;
