import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';

import {Dispatch, RootState} from 'environment/store';
import {JobRequestsDetailsTemplate} from 'templates';
import {ADD_HISTORY_SCHEMA} from 'environment/validation';
import {AddHistoryValue} from 'environment/types/Jobs';

const JobRequestsDetails = () => {
  const dispatch = useDispatch<Dispatch>();
  const params = useParams();

  const routeParams = params?.id
    ?.split('_')
    .map((route: string) => route.replace(/\D/g, ''));
    
  const jobRequestId = routeParams?.at(0);
  const seekerId = routeParams?.at(1);

  const [showModal, setShowModal] = useState<boolean>(false);

  const {jobRequestDetails, jobRequestsHistory} = useSelector(
    (state: RootState) => state.jobs,
  );
  const loading = useSelector(
    (rootState: RootState) => rootState.loading.models.jobs,
  );

  useEffect(() => {
    dispatch.jobs.getJobRequestDetails({
      id: jobRequestId,
      seekerId,
    });
    dispatch.jobs.getRequestHistory({
      id: jobRequestId,
      seekerId,
    });
  }, [dispatch.jobs, jobRequestId, seekerId]);

  const {reset, handleSubmit, control} = useForm<AddHistoryValue>({
    mode: 'onChange',
    defaultValues: {message: ''},
    resolver: ADD_HISTORY_SCHEMA,
  });

  const onChangeStatus = (status: string) => {
    const query = 'status=' + status;
    dispatch.jobs.changeJobRequstStatus({id: jobRequestId, seekerId, query});
  };

  const deleteAllHistory = () => {
    dispatch.jobs.deleteAllHistory({
      id: jobRequestId,
      seekerId,
    });
  };
  const addHistory = (value: AddHistoryValue) => {
    dispatch.jobs.addHistory({
      id: jobRequestId,
      seekerId,
      body: value,
    });
    setShowModal(false);
    reset();
  };

  return (
    <JobRequestsDetailsTemplate
      jobRequestDetails={jobRequestDetails}
      jobRequestsHistory={jobRequestsHistory}
      showModal={showModal}
      control={control}
      loading={loading}
      setShowModal={setShowModal}
      onChangeStatus={onChangeStatus}
      deleteAllHistory={deleteAllHistory}
      addHistory={handleSubmit(addHistory)}
    />
  );
};

export default JobRequestsDetails;
