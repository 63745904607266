import {Box, Paper, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import VerifiedIcon from '@mui/icons-material/Verified';
import {Control} from 'react-hook-form';

import {EditEmployerForm} from 'organisms';
import {Button} from 'atoms';

interface EditEmployerProps {
  control: Control<any>;
  loading: boolean;
  isVerify: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  onVerify: () => void;
}

const EditEmployerTemplate = ({
  control,
  loading,
  isVerify,
  onCancel,
  onSubmit,
  onVerify,
}: EditEmployerProps) => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Paper sx={{p: 7, ml: 7}}>
        <Typography textAlign={'center'} mb={3} variant="h4">
          {t('Edit.employer')}
        </Typography>
        <EditEmployerForm
          control={control}
          onSubmit={onSubmit}
          onCancel={onCancel}
          loading={loading}
        />
      </Paper>
      <Paper
        sx={{
          ml: 5,
          p: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Typography textAlign={'center'} mb={3} variant="h4">
          {isVerify ? t('Employer.Verified') : t('Verify.Employer')}
        </Typography>
        {isVerify ? (
          <VerifiedIcon color="success" sx={{fontSize: 45}} />
        ) : (
          <Button onClick={onVerify} fullWidth color="success">
            {t('Verify')}
          </Button>
        )}
      </Paper>
    </Box>
  );
};

export default EditEmployerTemplate;
