import axiosService from '../axios';
import {seekerUrl} from '../url';

export const getSeekersRequest = (query: string) => {
  return axiosService.get(seekerUrl({query}).getSeekers);
};
export const getSeekerRequest = (id: string) => {
  return axiosService.get(seekerUrl({id}).getSeeker);
};
export const editSeekerRequest = (id: string, body: any) => {
  return axiosService.patch(seekerUrl({id}).editSeeker, body);
};
