import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Dispatch, RootState} from 'environment/store';
import {HistoryTemplate} from 'templates';
import {formatDate} from 'environment/utils/formatDate';

const History = () => {
  const dispatch = useDispatch<Dispatch>();

  const {history} = useSelector((state: RootState) => state.history);

  useEffect(() => {
    dispatch.history.getHistory();
  }, [dispatch.history]);

  const bodyRows = history.map(value => ({
    id: value.id,
    admin_id: value.admin_id,
    type: value.type,
    message: value.message,
    createdAt: formatDate(value.createdAt),
    updatedAt: formatDate(value.updatedAt),
  }));

  return <HistoryTemplate bodyRows={bodyRows} />;
};

export default History;
