import {Box, Paper, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Control} from 'react-hook-form';

import {EditValues} from 'environment/types/Seeker';
import {EditSeekerForm} from 'organisms';

interface EditSeekerProps {
  control: Control<EditValues>;
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const EditSeekerTemplate = ({
  control,
  loading,
  onCancel,
  onSubmit,
}: EditSeekerProps) => {
  const {t} = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}>
      <Box p={7} component={Paper}>
        <Typography textAlign={'center'} mb={3} variant="h4">
          {t('Edit.seeker')}
        </Typography>
        <EditSeekerForm
          control={control}
          onSubmit={onSubmit}
          onCancel={onCancel}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default EditSeekerTemplate;
