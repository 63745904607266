export const routes = {
  dashboard: '/',
  notFound: '*',
  login: '/login',
  employers: {
    employers: '/employers',
    employersId: '/employers/:id',
  },
  seeker: {
    seekers: '/seekers',
    seekersId: '/seekers/:id',
  },
  jobs: {
    list: '/jobs-list',
    listDetailsId: '/jobs-list/:id',
    requestsList: '/job-requests-list',
    requestsDetailsId: '/job-requests-list/:id',
  },
  history: '/history',
};
