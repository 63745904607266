import {Box, CircularProgress, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Control} from 'react-hook-form';

import {Button, Input, Selector} from 'atoms';
import {
  AddHistoryValue,
  JobRequestDetails,
  JobRequestsHistory,
} from 'environment/types/Jobs';
import {ModalPopup} from 'molecules';
import {JobRequestDetailsTables, JobRequestHistoryTable} from 'organisms';

interface JobRequestsDetailsProps {
  jobRequestDetails: JobRequestDetails | null;
  jobRequestsHistory: JobRequestsHistory[];
  showModal: boolean;
  loading: boolean;
  control: Control<AddHistoryValue>;
  setShowModal: (value: boolean) => void;
  onChangeStatus: (value: string) => void;
  deleteAllHistory: () => void;
  addHistory: () => void;
}

const JobRequestsDetailsTemplate = ({
  jobRequestDetails,
  jobRequestsHistory,
  showModal,
  loading,
  control,
  setShowModal,
  onChangeStatus,
  deleteAllHistory,
  addHistory,
}: JobRequestsDetailsProps) => {
  const {t} = useTranslation();

  const StatusOfJobRequest = [
    {value: 'request', label: t('Request')},
    {value: 'accepted', label: t('Accepted')},
    {value: 'rejected', label: t('Rejected')},
    {value: 'cancelled', label: t('Cancelled')},
  ];

  if (loading) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        <CircularProgress size={70} />
      </Box>
    );
  }

  return (
    <>
      {jobRequestDetails && (
        <>
          <Selector
            menuItems={StatusOfJobRequest}
            value={jobRequestDetails.status}
            label={t('Job.request.status')}
            handleChange={onChangeStatus}
          />
          <JobRequestDetailsTables jobRequestDetails={jobRequestDetails} />
        </>
      )}
      <JobRequestHistoryTable
        jobRequestsHistory={jobRequestsHistory}
        onOpenModal={() => setShowModal(true)}
        onDeleteAllHistory={deleteAllHistory}
      />
      <ModalPopup open={showModal} onClose={() => setShowModal(false)}>
        <Typography align="center" variant="h4" mb={4}>
          {t('Add.history')}
        </Typography>
        <Box
          component="form"
          onSubmit={addHistory}
          autoComplete="off"
          textAlign="center">
          <Box sx={{mb: 2, height: '75px', width: 500}}>
            <Input
              control={control}
              name="message"
              label={t('Message')}
              fullWidth
            />
          </Box>
          <Button type="submit">{t('Submit')}</Button>
        </Box>
      </ModalPopup>
    </>
  );
};

export default JobRequestsDetailsTemplate;
