import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DatePicker as MUIDatePicker} from '@mui/x-date-pickers/DatePicker';
import {Controller, Control} from 'react-hook-form';

interface DatePickerProps {
  control: Control<any>;
  name: string;
  [props: string]: any;
}

const DatePicker = ({name, control, ...props}: DatePickerProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => {
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MUIDatePicker
              {...field}
              PopperProps={{
                placement: 'top',
              }}
              {...props}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  error={Boolean(error)}
                  helperText={error && error.message}
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default DatePicker;
