import {Dialog, IconButton, Box} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ModalPopupProps {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const ModalPopup = ({open, children, onClose}: ModalPopupProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '10px',
          p: '15px',
        },
      }}>
      <Box>
        <Box sx={{textAlign: 'end'}}>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Dialog>
  );
};

export default ModalPopup;
