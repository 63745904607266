import {Box, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {TableContainer} from 'atoms';
import {JobRequestDetails} from 'environment/types/Jobs';
import {formatDate} from 'environment/utils/formatDate';
import {TableBody, TableHead, TableVerticalBody} from 'molecules';

interface JobRequestTablesProps {
  jobRequestDetails: JobRequestDetails;
}

const JobRequestDetailsTables = ({
  jobRequestDetails,
}: JobRequestTablesProps) => {
  const {t} = useTranslation();

  const showJobRequestDetails = {
    id: jobRequestDetails?.id,
    seekerId: jobRequestDetails?.seeker_id,
    jobId: jobRequestDetails?.job_id,
    status: jobRequestDetails?.status,
    companyName: jobRequestDetails?.companyName,
    jobDescription: jobRequestDetails?.jobDescription,
    jobType: jobRequestDetails?.jobType,
    jobShiftType: jobRequestDetails?.jobShiftType,
    jobTitle: jobRequestDetails?.jobTitle,
    payRate: jobRequestDetails?.payRate,
    location: jobRequestDetails?.location.id,
    workScheduleId: jobRequestDetails?.workSchedule
      .map(value => value.id)
      .join(', '),
    startTime: formatDate(jobRequestDetails?.startTime, 'hour'),
    endDate: formatDate(jobRequestDetails?.endDate, 'day'),
    createdAt: formatDate(jobRequestDetails?.createdAt),
    updatedAt: formatDate(jobRequestDetails?.updatedAt),
  };

  const showLocation = {
    id: jobRequestDetails?.location.id,
    state: jobRequestDetails?.location.state,
    city: jobRequestDetails?.location.city,
    street: jobRequestDetails?.location.street,
    number: jobRequestDetails?.location.number,
    zipcode: jobRequestDetails?.location.zipcode,
    createdAt: formatDate(jobRequestDetails?.createdAt),
    updatedAt: formatDate(jobRequestDetails?.updatedAt),
  };

  const showWorkSchedule = jobRequestDetails?.workSchedule.map(value => {
    return {
      id: value.id,
      jobId: value.job_id,
      seekerId: value.seeker_id,
      duration: value.duration,
      dayOfWeek: value.dayOfWeek,
      date: formatDate(value.date, 'day'),
      startTime: value.startTime,
      endTime: value.endTime,
      createdAt: formatDate(value.createdAt),
      updatedAt: formatDate(value.updatedAt),
    };
  });

  const headCellsWorkSchedule = [
    {
      value: 'id',
      label: t('Id'),
      sort: false,
    },
    {
      value: 'jobId',
      label: t('Job.id'),
      sort: false,
    },
    {
      value: 'seekerId',
      label: t('Seeker.id'),
      sort: false,
    },
    {
      value: 'duration',
      label: t('Duration'),
      sort: false,
    },
    {
      value: 'dayOfWeek',
      label: t('Day.of.week'),
      sort: false,
    },
    {
      value: 'date',
      label: t('Date'),
      sort: false,
    },
    {
      value: 'startTime',
      label: t('Start.time'),
      sort: false,
    },
    {
      value: 'endTime',
      label: t('End.time'),
      sort: false,
    },
    {
      value: 'createdAt',
      label: t('Created.at'),
      sort: false,
    },
    {
      value: 'updatedAt',
      label: t('Updated.at'),
      sort: false,
    },
  ];

  return (
    <Stack mt={3} direction="row" gap={3} flexWrap="wrap">
      <Box width={'500px'}>
        <Typography variant="h5" align="center">
          {t('Job.request.details')}
        </Typography>
        <TableContainer minWidth={400}>
          <TableVerticalBody<typeof showJobRequestDetails>
            rows={showJobRequestDetails}
          />
        </TableContainer>
      </Box>
      <Box width={'500px'}>
        <Typography variant="h5" align="center">
          {t('Location')}
        </Typography>
        <TableContainer minWidth={400}>
          <TableVerticalBody<typeof showLocation> rows={showLocation} />
        </TableContainer>
      </Box>
      <Box>
        <Typography variant="h5" align="center">
          {t('Work.schedule')}
        </Typography>
        <TableContainer>
          <TableHead<typeof headCellsWorkSchedule>
            headCells={headCellsWorkSchedule}
          />
          <TableBody rows={showWorkSchedule} />
        </TableContainer>
      </Box>
    </Stack>
  );
};

export default JobRequestDetailsTables;
