import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Control} from 'react-hook-form';

import {Button, DatePicker, Input} from 'atoms';
import {EditValues} from 'environment/types/Seeker';
import {RadioButton} from 'molecules';

interface EditSeekerFormProps {
  control: Control<EditValues>;
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const EditSeekerForm = ({
  control,
  loading,
  onCancel,
  onSubmit,
}: EditSeekerFormProps) => {
  const {t} = useTranslation();

  const radioBtnOptions = [
    {
      label: t('Male'),
      value: 'male',
    },
    {
      label: t('Female'),
      value: 'female',
    },
  ];
  const inputList = [
    {
      name: 'id',
      label: t('Seeker.id'),
      disabled: true,
    },
    {
      name: 'email',
      label: t('Email'),
      disabled: true,
    },
    {
      name: 'firstName',
      label: t('First.name'),
      disabled: false,
    },
    {
      name: 'lastName',
      label: t('Last.name'),
      disabled: false,
    },
    {
      name: 'securityNumber',
      label: t('Security number'),
      disabled: false,
    },
  ];

  return (
    <Box
      sx={{display: 'flex', flexDirection: 'column', width: '400px'}}
      component="form"
      onSubmit={onSubmit}
      autoComplete="off">
      {inputList.map(value => (
        <Box key={value.name} sx={{mb: 2, height: '75px'}}>
          <Input
            name={value.name}
            disabled={value.disabled}
            control={control}
            label={value.label}
            fullWidth
          />
        </Box>
      ))}
      <Box sx={{mb: 1, height: '75px'}}>
        <DatePicker name="dob" control={control} label={t('Date.of.birth')} />
      </Box>
      <Box sx={{mb: 1, height: '75px'}}>
        <RadioButton
          name="gender"
          control={control}
          label={t('Gender')}
          options={radioBtnOptions}
          row
        />
      </Box>
      <Box sx={{display: 'flex', gap: '50px'}}>
        <Button onClick={onCancel} fullWidth color="error">
          {t('Cancel')}
        </Button>
        <Button fullWidth loading={loading} disabled={loading} type="submit">
          {t('Save')}
        </Button>
      </Box>
    </Box>
  );
};

export default EditSeekerForm;
