import {Box, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {Switcher, TableContainer} from 'atoms';
import {JobsListDetails} from 'environment/types/Jobs';
import {formatDate} from 'environment/utils/formatDate';
import {TableVerticalBody} from 'molecules';

interface JobDetailsProps {
  jobStatus: boolean;
  jobDetails: JobsListDetails | null;
  onChangeStatus: () => void;
}

const JobDetailsTemplate = ({
  jobDetails,
  jobStatus,
  onChangeStatus,
}: JobDetailsProps) => {
  const {t} = useTranslation();

  const showDetailsInfo = {
    id: jobDetails?.id,
    employer_id: jobDetails?.employer_id,
    title: jobDetails?.title,
    companyName: jobDetails?.companyName,
    description: jobDetails?.description,
    contactInfo: jobDetails?.contactInfo,
    phoneNumber: jobDetails?.phoneNumber,
    hoursPerWeek: jobDetails?.hoursPerWeek,
    jobType: jobDetails?.jobType,
    minExperience: jobDetails?.minExperience,
    payRate: jobDetails?.payRate,
    type: jobDetails?.type,
    status: jobDetails?.status,
    startDate: formatDate(jobDetails?.startDate, 'day'),
    endDate: formatDate(jobDetails?.endDate, 'day'),
    createdAt: formatDate(jobDetails?.createdAt),
    updatedAt: formatDate(jobDetails?.updatedAt),
  };

  return (
    <>
      {jobDetails && (
        <>
          <Stack alignItems="center" direction="row" gap={3} mb={3}>
            <Typography sx={{fontWeight: 'bold'}} component={'span'}>
              {t('Job.status')}:
            </Typography>
            <Switcher
              color="success"
              active={jobStatus}
              onChange={onChangeStatus}
              switchOptions={{left: 'inactive', right: 'active'}}
            />
          </Stack>
          <Box width={'550px'}>
            <TableContainer minWidth={400}>
              <TableVerticalBody<typeof showDetailsInfo>
                rows={showDetailsInfo}
              />
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
};

export default JobDetailsTemplate;
