import {useTranslation} from 'react-i18next';

import {TableBody, TableHead} from 'molecules';
import {TableContainer} from 'atoms';
import {HistoryData, HistoryHeadCell} from 'environment/types/History';

interface HistoryProps {
  bodyRows: HistoryData[];
}

const HistoryTemplate = ({bodyRows}: HistoryProps) => {
  const {t} = useTranslation();

  const headCells: HistoryHeadCell[] = [
    {
      value: 'id',
      label: t('Id'),
      sort: false,
    },
    {
      value: 'admin_id',
      label: t('Admin.id'),
      sort: false,
    },
    {
      value: 'type',
      label: t('Type'),
      sort: false,
    },
    {
      value: 'message',
      label: t('Message'),
      sort: false,
    },
    {
      value: 'createdAt',
      label: t('Created.at'),
      sort: false,
    },
    {
      value: 'updatedAt',
      label: t('Updated.at'),
      sort: false,
    },
  ];

  return (
    <TableContainer>
      <TableHead headCells={headCells} />
      <TableBody rows={bodyRows} />
    </TableContainer>
  );
};

export default HistoryTemplate;
