import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {Dispatch, RootState} from 'environment/store';
import {JobRequestsListTemplate} from 'templates';
import {routes} from 'environment/constants/routes';
import {JobRequestsList as JobRequestsListType} from 'environment/types/Jobs';

const JobRequestsList = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const {jobRequestsList} = useSelector((state: RootState) => state.jobs);

  useEffect(() => {
    dispatch.jobs.getJobRequsts({query: ''});
  }, [dispatch.jobs]);

  const navigateToDetailsPage = (values: JobRequestsListType) => {
    navigate(
      `${routes.jobs.requestsList}/request-${values.id}_seeker-${values.seeker_id}`,
    );
  };

  return (
    <JobRequestsListTemplate
      jobRequestsList={jobRequestsList}
      navigateToDetailsPage={navigateToDetailsPage}
    />
  );
};

export default JobRequestsList;
