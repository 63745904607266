import axios, {InternalAxiosRequestConfig, AxiosHeaders} from 'axios';

import {workBridge} from 'environment/constants';

const axiosService = axios.create({baseURL: process.env.REACT_APP_API_URL});

axiosService.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const accessTokenLS = localStorage.getItem(workBridge);
  const accessTokenSS = sessionStorage.getItem(workBridge);

  if (!accessTokenLS && !accessTokenSS) {
    return config;
  }

  (config.headers as AxiosHeaders).set(
    'Authorization',
    `Bearer ${accessTokenLS ? accessTokenLS : accessTokenSS}`,
  );

  return config;
});

axiosService.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.data.statusCode === 401) {
        sessionStorage.removeItem(workBridge);
        localStorage.removeItem(workBridge);
      }
      return Promise.reject(error.response.data);
    }
  },
);

export default axiosService;
