import axiosService from '../axios';
import {jobsUrl} from '../url';

export const getJobsRequest = (query: string) => {
  return axiosService.get(jobsUrl({query}).getJobs);
};

export const getJobDetailsRequest = (id: number) => {
  return axiosService.get(jobsUrl({id}).getJobDetails);
};

export const changeJobStatusRequest = (id: number, query: string) => {
  return axiosService.patch(jobsUrl({id, query}).changeJobStatus);
};

export const getJobRequestsListRequest = (query: string) => {
  return axiosService.get(jobsUrl({query}).getJobRequestsList);
};

export const getJobRequestDetailsRequest = (id: number, seekerId: number) => {
  return axiosService.get(jobsUrl({id, seekerId}).getJobRequest);
};

export const changeJobRequstStatusRequest = (
  id: number,
  seekerId: number,
  query: string,
) => {
  return axiosService.patch(
    jobsUrl({id, seekerId, query}).controlSeekerRequest,
  );
};

export const getRequestHistoryRequest = (id: number, seekerId: number) => {
  return axiosService.get(jobsUrl({id, seekerId}).getRequestHistory);
};

export const deleteAllHistoryRequest = (id: number, seekerId: number) => {
  return axiosService.delete(jobsUrl({id, seekerId}).controlSeekerRequest);
};

export const addHistoryRequest = (
  id: number,
  seekerId: number,
  body: {message: string},
) => {
  return axiosService.post(jobsUrl({id, seekerId}).controlSeekerRequest, body);
};
