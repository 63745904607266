import {createModel} from '@rematch/core';
import {AxiosError} from 'axios';
import {toast} from 'react-toastify';
import {t} from 'i18next';

import type {RootModel} from '.';
import {logInRequest} from 'environment/api/services/auth';
import {LogInDataType} from 'environment/types/LogIn';

export const auth = createModel<RootModel>()({
  state: {
    admin: '',
  },
  reducers: {},
  effects: {
    async logIn(payload: LogInDataType) {
      try {
        const {status, data} = await logInRequest(payload.data);
        if (status === 201) {
          payload.callback(data.id_token);
        }
      } catch (error) {
        const err = error as AxiosError & {statusCode: number};

        if (err.statusCode === 400) {
          toast.error(t('Must.be.a.valid.email'));
          return;
        }
        toast.error(err.message);
      }
    },
  },
});
