import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';

import {ReactComponent as Logo} from 'environment/assets/icons/Logo.svg';
import {routes} from 'environment/constants/routes';
import {useTranslation} from 'react-i18next';

interface SideBarProps {
  drawerWidth: number;
  headerHeight: number;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const SideBar = ({
  drawerWidth,
  headerHeight,
  mobileOpen,
  handleDrawerToggle,
}: SideBarProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const sizeIcon = headerHeight - 15 + 'px';
  const color = '#9da4ae';
  const {pathname} = location;

  const getPathName = (path: string) => {
    return path.split('/').at(1);
  };
  const pathName = getPathName(pathname);

  const sideBarList = [
    {
      title: t('Seekers'),
      path: routes.seeker.seekers,
    },
    {
      title: t('Employers'),
      path: routes.employers.employers,
    },
    {
      title: t('Jobs.list'),
      path: routes.jobs.list,
    },
    {
      title: t('Job.requests.list'),
      path: routes.jobs.requestsList,
    },
    {
      title: t('History'),
      path: routes.history,
    },
  ];

  const drawer = (
    <>
      <Box
        sx={{
          height: headerHeight + 'px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Logo
          style={{
            height: sizeIcon,
            width: sizeIcon,
          }}
        />
      </Box>
      <Divider />
      <List>
        {sideBarList.map(({title, path}) => (
          <ListItem
            key={title}
            disablePadding
            sx={{
              color: getPathName(path) === pathName ? '#fff' : color,
              transform:
                getPathName(path) === pathName ? 'scale(1.05)' : 'none',
            }}
            onClick={() => navigate(path)}>
            <ListItemButton>
              <ListItemText primary={title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );

  return (
    <Box component="nav" sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: {xs: 'block', sm: 'none'},
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            border: 'none',
            backgroundColor: '#1c2536',
            overflowX: 'hidden',
          },
        }}>
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: {xs: 'none', sm: 'block'},
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            border: 'none',
            backgroundColor: '#282f3d',
            overflowX: 'hidden',
          },
        }}
        open>
        {drawer}
      </Drawer>
    </Box>
  );
};

export default SideBar;
