import {createModel} from '@rematch/core';
import {AxiosError} from 'axios';
import {toast} from 'react-toastify';
import {t} from 'i18next';

import type {RootModel} from '.';
import {
  editSeekerRequest,
  getSeekerRequest,
  getSeekersRequest,
} from 'environment/api/services/sekers';
import {SeekerData, SeekersState} from 'environment/types/Seeker';

const seeker = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  dob: '',
  gender: '',
  securityNumber: '',
};

export const seekers = createModel<RootModel>()({
  state: {
    seekers: [],
    seeker: seeker,
  } as SeekersState,
  reducers: {
    setSeekers(state, payload: SeekerData[]) {
      return {
        ...state,
        seekers: payload,
      };
    },
    setSeeker(state, payload: SeekerData) {
      return {
        ...state,
        seeker: payload,
      };
    },
  },
  effects: {
    async getSeekers(payload) {
      try {
        const {status, data} = await getSeekersRequest(payload.query);
        if (status === 200) {
          this.setSeekers(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async getSeeker(payload) {
      try {
        const {status, data} = await getSeekerRequest(payload.id);
        if (status === 200) {
          this.setSeeker(data);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    async editSeeker(payload) {
      const {id, body} = payload;
      try {
        const {status, data} = await editSeekerRequest(id, body);
        if (status === 200) {
          this.setSeeker(data);
          toast.success(t('Changes.were.successfully.saved'));
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
  },
});
