import {useTranslation} from 'react-i18next';

import {TableBody, TableHead} from 'molecules';
import {TableContainer} from 'atoms';
import {CellData, HeadCell, SeekerData} from 'environment/types/Seeker';
import {Order} from 'environment/types';

interface SeekersProps {
  orderBy: keyof CellData;
  order: Order;
  bodyRows: Partial<SeekerData>[];
  handleRequestSort: (property: keyof CellData) => void;
  navigateToEditPage: (values: SeekerData) => void;
}

const SeekersTemplate = ({
  orderBy,
  order,
  bodyRows,
  handleRequestSort,
  navigateToEditPage,
}: SeekersProps) => {
  const {t} = useTranslation();

  const headCells: HeadCell[] = [
    {
      value: 'id',
      label: t('Id'),
      sort: false,
    },
    {
      value: 'firstName',
      label: t('First.name'),
      sort: true,
    },
    {
      value: 'lastName',
      label: t('Last.name'),
      sort: true,
    },
    {
      value: 'phoneNumber',
      label: t('Phone.number'),
      sort: true,
    },
    {
      value: 'email',
      label: t('Email'),
      sort: true,
    },
    {
      value: 'createdAt',
      label: t('Created.at'),
      sort: true,
    },
    {
      value: 'updatedAt',
      label: t('Updated.at'),
      sort: true,
    },
    {
      value: 'edit',
      label: t('Edit'),
      sort: false,
    },
  ];

  return (
    <TableContainer>
      <TableHead<CellData>
        headCells={headCells}
        orderBy={orderBy}
        order={order}
        sortHandler={handleRequestSort}
      />
      <TableBody rows={bodyRows} editBtn editHandler={navigateToEditPage} />
    </TableContainer>
  );
};

export default SeekersTemplate;
