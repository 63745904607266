import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@mui/material';
import {Controller, Control} from 'react-hook-form';

interface Options {
  label: string;
  value: string;
}

interface RadioButtonProps {
  name: string;
  control: Control<any>;
  label: string;
  row?: boolean;
  options: Options[];
}

const RadioButton = ({
  name,
  control,
  label,
  options,
  row = false,
}: RadioButtonProps) => {
  const radioOptions = () => {
    return options.map(option => (
      <FormControlLabel
        key={option.value}
        value={option.value}
        label={option.label}
        control={<Radio />}
      />
    ));
  };

  return (
    <FormControl>
      <Controller
        name={name}
        control={control}
        render={({field, fieldState: {error}}) => {
          return (
            <>
              <FormLabel error={Boolean(error)}>{label}</FormLabel>
              <RadioGroup row={row} {...field}>
                {radioOptions()}
              </RadioGroup>
              <FormHelperText sx={{mt: '-5px'}} error>
                {error && error.message}
              </FormHelperText>
            </>
          );
        }}
      />
    </FormControl>
  );
};

export default RadioButton;
