import axiosService from '../axios';
import {employerUrl} from '../url';

export const getEmployersRequest = (query: string) => {
  return axiosService.get(employerUrl({query}).getEmployers);
};

export const getEmployerRequest = (id: string) => {
  return axiosService.get(employerUrl({id}).getEmployer);
};

export const editEmployerRequest = (id: string, body: any) => {
  return axiosService.patch(employerUrl({id}).editEmployer, body);
};

export const verifyEmployerRequest = (id: string, verified: boolean) => {
  return axiosService.patch(employerUrl({id, verified}).verifyEmployer);
};
