import {EmployerUrl} from 'environment/types/Employer';
import {JobsUrl} from 'environment/types/Jobs';
import {SeekerUrl} from 'environment/types/Seeker';

export const authUrl = {
  login: '/auth/admin/login',
};

export const seekerUrl = ({id, query}: SeekerUrl) => {
  const queryParams = query ? '?' + query : '';

  return {
    getSeekers: `/admin/control/seekers${queryParams}`,
    getSeeker: `/seeker/${id}`,
    editSeeker: `/admin/control/seeker/${id}`,
  };
};

export const employerUrl = ({id, query, verified}: EmployerUrl) => {
  const queryParams = query ? '?' + query : '';

  return {
    getEmployers: `/admin/control/employers${queryParams}`,
    getEmployer: `/employer/${id}`,
    editEmployer: `/admin/control/employer/${id}`,
    verifyEmployer: `/admin/control/employer/${id}/verify/${verified}`,
  };
};

export const jobsUrl = ({id, query, seekerId}: JobsUrl) => {
  const queryParams = query ? '?' + query : '';

  return {
    getJobs: `/admin/control/jobs${queryParams}`,
    getJobDetails: `/job/${id}`,
    changeJobStatus: `/admin/control/job/${id}${queryParams}`,
    getJobRequestsList: `/admin/control/job-requests${queryParams}`,
    getJobRequest: `/seeker/${seekerId}/job-request/${id}`,
    controlSeekerRequest: `/admin/control/seeker/${seekerId}/job-request/${id}${queryParams}`,
    getRequestHistory: `/seeker/${seekerId}/job-request/${id}/history`,
  };
};

export const historyUrl = () => {
  return {
    getHistory: '/admin/action-logs',
  };
};
