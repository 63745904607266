import {useTranslation} from 'react-i18next';

import {TableBody, TableHead} from 'molecules';
import {TableContainer} from 'atoms';
import {JobsListHeadCell, JobsListDetails} from 'environment/types/Jobs';

interface JobListProps {
  bodyRows: Partial<JobsListDetails>[];
  navigateToDetailsPage: (values: JobsListDetails) => void;
}

const JobListTemplate = ({bodyRows, navigateToDetailsPage}: JobListProps) => {
  const {t} = useTranslation();

  const headCells: JobsListHeadCell[] = [
    {
      value: 'id',
      label: t('Id'),
      sort: false,
    },
    {
      value: 'title',
      label: t('Title'),
      sort: false,
    },
    {
      value: 'phoneNumber',
      label: t('Phone.number'),
      sort: false,
    },
    {
      value: 'payRate',
      label: t('Pay.rate'),
      sort: false,
    },
    {
      value: 'companyName',
      label: t('Company.name'),
      sort: false,
    },
    {
      value: 'createdAt',
      label: t('Created.at'),
      sort: false,
    },
    {
      value: 'updatedAt',
      label: t('Updated.at'),
      sort: false,
    },
    {
      value: 'endDate',
      label: t('End.date'),
      sort: false,
    },
    {
      value: 'details',
      label: t('Details'),
      sort: false,
    },
  ];

  return (
    <TableContainer>
      <TableHead headCells={headCells} />
      <TableBody rows={bodyRows} editBtn editHandler={navigateToDetailsPage} />
    </TableContainer>
  );
};

export default JobListTemplate;
