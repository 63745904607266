import {useTranslation} from 'react-i18next';

import {TableBody, TableHead} from 'molecules';
import {TableContainer} from 'atoms';
import {JobRequestsListHeadCell, JobRequestsList} from 'environment/types/Jobs';
import {formatDate} from 'environment/utils/formatDate';

interface JobRequestsListProps {
  jobRequestsList: JobRequestsList[];
  navigateToDetailsPage: (values: JobRequestsList) => void;
}

const JobRequestsListTemplate = ({
  jobRequestsList,
  navigateToDetailsPage,
}: JobRequestsListProps) => {
  const {t} = useTranslation();

  const headCells: JobRequestsListHeadCell[] = [
    {
      value: 'id',
      label: t('Id'),
      sort: false,
    },
    {
      value: 'status',
      label: t('Status'),
      sort: false,
    },
    {
      value: 'payRate',
      label: t('Pay.rate'),
      sort: false,
    },
    {
      value: 'job_id',
      label: t('Job.id'),
      sort: false,
    },
    {
      value: 'seeker_id',
      label: t('Seeker.id'),
      sort: false,
    },
    {
      value: 'endDate',
      label: t('End.date'),
      sort: false,
    },
    {
      value: 'createdAt',
      label: t('Created.at'),
      sort: false,
    },
    {
      value: 'updatedAt',
      label: t('Updated.at'),
      sort: false,
    },
    {
      value: 'details',
      label: t('Details'),
      sort: false,
    },
  ];

  const bodyRows = jobRequestsList.map(value => ({
    id: value.id,
    status: value.status,
    payRate: value.payRate,
    job_id: value.job_id,
    seeker_id: value.seeker_id,
    endDate: formatDate(value.endDate, 'day'),
    createdAt: formatDate(value.createdAt),
    updatedAt: formatDate(value.updatedAt),
  }));

  return (
    <TableContainer>
      <TableHead headCells={headCells} />
      <TableBody rows={bodyRows} editBtn editHandler={navigateToDetailsPage} />
    </TableContainer>
  );
};

export default JobRequestsListTemplate;
