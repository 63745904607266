import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {Dispatch, RootState} from 'environment/store';
import {EditEmployerTemplate} from 'templates';
import {EDIT_EMPLOYER_SCHEMA} from 'environment/validation';
import {routes} from 'environment/constants/routes';
import {EditValues, EmployersData} from 'environment/types/Employer';

const EditEmployer = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const params = useParams();

  const {employer} = useSelector((state: RootState) => state.employers);
  const loading = useSelector(
    (rootState: RootState) => rootState.loading.models.employers,
  );

  useEffect(() => {
    const employerId = params?.id?.replace(/\D/g, '');
    dispatch.employers.getEmployer({id: employerId});
  }, [dispatch, params.id]);

  const setDefaultValues = (employer: EmployersData) => {
    const [firstName, ...rest] = employer.name.split(' ');

    return {
      id: employer.id,
      email: employer.email || '',
      firstName,
      lastName: rest.join(' '),
      companyName: employer.companyName,
    };
  };

  const {
    reset,
    handleSubmit,
    control,
    formState: {isDirty},
  } = useForm<EditValues>({
    mode: 'onChange',
    defaultValues: setDefaultValues(employer),
    resolver: EDIT_EMPLOYER_SCHEMA,
  });

  useEffect(() => {
    reset(setDefaultValues(employer));
  }, [reset, employer]);

  const onSubmit = (values: EditValues) => {
    if (!isDirty) {
      return;
    }

    const {id, firstName, lastName, companyName, email} = values;
    const data = {
      name: firstName + ' ' + lastName,
      email,
      companyName,
    };

    dispatch.employers.editEmployer({id, body: data});
  };

  const navigateToEmployers = () => {
    navigate(routes.employers.employers);
  };

  const onVerify = () => {
    dispatch.employers.verifyEmployer({id: employer.id, verified: true});
  };

  return (
    <EditEmployerTemplate
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      onCancel={navigateToEmployers}
      loading={loading}
      isVerify={employer.verified}
      onVerify={onVerify}
    />
  );
};

export default EditEmployer;
