import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';

interface MenuItems {
  value: string;
  label: string;
}

interface SelectorProps {
  value: string;
  label: string;
  menuItems: MenuItems[];
  fullWidth?: boolean;
  handleChange: (value: string) => void;
}

const Selector = ({
  value,
  label,
  fullWidth = false,
  menuItems,
  handleChange,
}: SelectorProps) => {
  const menuItemsList = menuItems.map(item => (
    <MenuItem key={item.value} value={item.value}>
      {item.label}
    </MenuItem>
  ));

  return (
    <FormControl sx={{minWidth: 150}} fullWidth={fullWidth}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        onChange={e => handleChange(e.target.value)}>
        {menuItemsList}
      </Select>
    </FormControl>
  );
};

export default Selector;
