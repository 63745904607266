import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {Dispatch, RootState} from 'environment/store';
import {JobListTemplate} from 'templates';
import {routes} from 'environment/constants/routes';
import {formatDate} from 'environment/utils/formatDate';
import {JobsListDetails} from 'environment/types/Jobs';

const JobList = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const {jobsList} = useSelector((state: RootState) => state.jobs);

  useEffect(() => {
    dispatch.jobs.getJobs({query: ''});
  }, [dispatch.jobs]);

  const navigateToDetailsPage = (values: JobsListDetails) => {
    navigate(`${routes.jobs.list}/job-${values.id}`);
  };

  const bodyRows = jobsList.map(value => ({
    id: value.id,
    title: value.title,
    phoneNumber: value.phoneNumber,
    payRate: value.payRate,
    companyName: value.companyName,
    createdAt: formatDate(value.createdAt),
    updatedAt: formatDate(value.updatedAt),
    endDate: formatDate(value.endDate, 'day'),
  }));

  return (
    <JobListTemplate
      bodyRows={bodyRows}
      navigateToDetailsPage={navigateToDetailsPage}
    />
  );
};

export default JobList;
